import './SectionTitle.scss';

const SectionTitle = props => {
  return (
    <div className="section-title">
      <h2>
        {props.first}<br/>
        <span className="outlined">{props.second}</span>
      </h2>
    </div>
  );
}

export default SectionTitle;