import SectionTitle from '../elements/SectionTitle';
import Footer from '../sections/Footer';
import ContactForm from '../sections/ContactForm';
import AllWorksGallery from '../sections/AllWorksGallery';

import './WorksPage.scss';

const WorksPage = props => {
  return (
    <>
      <section id="allworks" className="allworks-section section-page first-section">
          <div className="page-content">
            <SectionTitle first="Please have" second="a look" />
            <AllWorksGallery />
          </div>
      </section>
      <ContactForm />
      <Footer />
    </>
  );
}

export default WorksPage;