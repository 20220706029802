import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import WorksPage from './components/pages/WorksPage';
import HomePage from './components/pages/HomePage';
import WorkPage from './components/pages/WorkPage';

import Loader from './components/sections/Loader.js';
import Navbar from './components/layout/Navbar.js';


const App = () => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const onPageLoad = () => {
      setIsLoading(false);
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <>
      <Loader hidden={isLoading ? false : true} />
      {!isLoading &&
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/works" element={<WorksPage />} />
            <Route path="/works/:workId" element={<WorkPage />} />
          </Routes>
        </BrowserRouter>
      }
    </>
  );
}

export default App;
