import { useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { isLoaded } from "../../state/atoms/isLoaded";

import './Hero.scss';

import HeroImg from '../../assets/image/pictures/hero.jpg'

const Hero = () => {
  const [hasLoaded, setHasLoaded] = useRecoilState(isLoaded)

  useEffect(() => {
    return () => {
      setHasLoaded(true)
    }
  }, [])

  return (
    <section id="hero" className={`hero${hasLoaded ? ' isloaded' : ''}`}>
        <div className="hero__title name">
            <span>Kate</span><br/>
            <span>Poliakova</span>
        </div>
        <div className="hero__img-container">
        </div>
        <div className="hero__title resume">
            <span className="activity">Photography</span><br/>
            <span className="examples">Wedding &#9679; Events &#9679; Business</span>
        </div>
        <div className="hero__button">
          <a href="#about">
            <div className="hero__button__link"></div>
          </a>
        </div>
    </section>
  );
}

export default Hero;