import './Quote.scss';

const Quote = props => {
  return (
    <section id="quote" className="quote-section section-page">
        <div className="page-content">
            <h2>" A thing that <em>you see</em> in my <em>pictures</em> is that I was <em>not afraid</em> to fall in <em>love</em> with these people "</h2>
            <hr />
            <span className="quote-author">Annie Leibovitz</span>
        </div>
    </section>
  );
}

export default Quote;