import './PhotoViewer.scss';

const PhotoViewer = props => {
  return (
    <div onClick={props.hidePicture} className="photoviewer-container">
        <img src={props.img} onClick={(e) => e.stopPropagation()} className='photoviewer-img'/>
        <div className="photoviewer-closebutton"></div>
    </div>
  );
}

export default PhotoViewer;