import './Loader.scss';

const Loader = props => {
  return (
    <section id="loader" className={`loader-wrapper${props.hidden ? ' hidden' : ''}`}>
        <span className="loader"></span>
    </section>
  );
}

export default Loader;