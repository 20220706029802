import { Link } from "react-router-dom"

import './AllWorksGallery.scss';

const gallery = [
  {
    headline: 'Ma boy',
    location: 'Kyev',
    country: 'UA',
    topic: 'topic',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/225363/fashion.jpg'
  },
  {
    headline: 'Ma boy',
    location: 'Kyev',
    country: 'UA',
    topic: 'topic',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/225363/fashion.jpg'
  },
  {
    headline: 'Ma boy',
    location: 'Kyev',
    country: 'UA',
    topic: 'topic',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/225363/fashion.jpg'
  },
  {
    headline: 'Ma boy',
    location: 'Kyev',
    country: 'UA',
    topic: 'topic',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/225363/fashion.jpg'
  },
  {
    headline: 'Ma boy',
    location: 'Kyev',
    country: 'UA',
    topic: 'topic',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/225363/fashion.jpg'
  },
  {
    headline: 'Ma boy',
    location: 'Kyev',
    country: 'UA',
    topic: 'topic',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/225363/fashion.jpg'
  },
  {
    headline: 'Ma boy',
    location: 'Kyev',
    country: 'UA',
    topic: 'topic',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/225363/fashion.jpg'
  },
  {
    headline: 'Ma boy',
    location: 'Kyev',
    country: 'UA',
    topic: 'topic',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/225363/fashion.jpg'
  },
  {
    headline: 'Ma boy',
    location: 'Kyev',
    country: 'UA',
    topic: 'topic',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/225363/fashion.jpg'
  },
  {
    headline: 'Ma boy',
    location: 'Kyev',
    country: 'UA',
    topic: 'topic',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/225363/fashion.jpg'
  },
  {
    headline: 'Ma boy',
    location: 'Kyev',
    country: 'UA',
    topic: 'topic',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/225363/fashion.jpg'
  },
  {
    headline: 'Ma boy',
    location: 'Kyev',
    country: 'UA',
    topic: 'topic',
    src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/225363/fashion.jpg'
  }
]

const AllWorksGallery = props => {
  return (
    <div className="allworks">
        { gallery.map((slide, idx) => {
          return (
              <Link key={idx} to={`/works/${idx}`} className="allworks__image-wrapper">
                <div className="desc-box">
                    <div className="desc-box__title">
                    <h3>{slide.headline}</h3>
                    </div>
                    <div className="desc-box__details">
                    <span>{slide.topic}</span>
                    <span>{slide.location}, {slide.country}</span>
                    </div>
                </div>
                <img src="https://images.unsplash.com/photo-1664261840765-1d8dc5b4d7d9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80" alt="" />
              </Link>
          )
        })}
    </div>
  );
}

export default AllWorksGallery;