import './HomePage.scss';

import Hero from '../sections/Hero.js';
import About from '../sections/About.js';
import Works from '../sections/Works.js';
import Quote from '../sections/Quote.js';
import ContactForm from '../sections/ContactForm.js';
import Footer from '../sections/Footer';

const Home = props => {
  return (
    <>
        <Hero />
        <About />
        <Works />
        <Quote />
        <ContactForm />
        <Footer />
    </>
  );
}

export default Home;