import './Navbar.scss';
import MainLogo from "../../assets/image/icons/camera.svg";
import { Link } from "react-router-dom"

const Navbar = () => {
  return (
    <header>
        <nav className="navbar-section navbar__left-navigation">
            <ul>
              <Link to="/">Home</Link>
              <Link to="/works">Works</Link>
            </ul>
        </nav>
        <div className="navbar__logo">
            <img src={MainLogo} alt="" />
        </div>
        <nav className="navbar-section navbar__left-navigation">
          <ul>
            <li><a href="https://www.instagram.com/poliakova.paris/" target="_blank">Insta</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
    </header>
  );
}

export default Navbar;