
import { useState } from 'react';
import axios from 'axios';
import SectionTitle from '../elements/SectionTitle';

import './ContactForm.scss';

const ContactForm = props => {
  const [mailData, setMailData] = useState({
    name: '',
    email: '',
    location: '',
    phone: '',
    type: '',
    date: '',
    budget: '',
    message: ''
  })

  const handleFormSubmit = (e) => {
    e.preventDefault()
    const API_PATH = 'http://localhost/test/index.php';


    // axios({
    //   method: 'post',
    //   url: `${API_PATH}`,
    //   headers: { 'content-type': 'application/json' },
    //   data: mailData
    // })
    //   .then(result => {
    //     console.log(result.data)
    //   })
    //   .catch(error => console.log(error));

    console.log(mailData)
  }

  return (
    <section id="contact" className="contact-section section-page">
        <div className="page-content">
        <SectionTitle first="Let's get" second="further" />
          <form id="contact-form">
            <div className="input-holder">
              <input type="text" id="form-name" name="name" placeholder="Name" onInput={(e) => setMailData({...mailData, name: e.target.value})} />
            </div>
            <div className="input-holder">
              <input type="text" id="form-email" name="email" placeholder="Email" onInput={(e) => setMailData({...mailData, email: e.target.value})} />
            </div>
            <div className="input-holder">
              <input type="text" id="form-location" name="location" placeholder="Location" onInput={(e) => setMailData({...mailData, location: e.target.value})} />
            </div>
            <div className="input-holder">
              <input type="text" id="form-phone" name="phone" placeholder="Phone" onInput={(e) => setMailData({...mailData, phone: e.target.value})} />
            </div>
            <div className="input-holder">
              <select id="form-shooting-type" name="shooting-type" onInput={(e) => setMailData({...mailData, type: e.target.value})}>
                <option value="couples">Couples</option>
                <option value="family">Family</option>
                <option value="children">Children</option>
                <option value="professional">Professional Portrait</option>
                <option value="estate">Real Estate</option>
                <option value="others">Other</option>
              </select>
            </div>
            <div className="input-holder">
              <input type="text" id="form-date" name="date" placeholder="Date" onInput={(e) => setMailData({...mailData, date: e.target.value})} />
            </div>
            <div className="input-holder">
              <input type="text" id="form-budget" name="budget" placeholder="Budget" onInput={(e) => setMailData({...mailData, budget: e.target.value})} />
            </div>
            <div className="form-separator"></div>
            <label htmlFor="form-message">Tell me more!</label>
            <div className="input-holder">
              <textarea name="message" id="form-message" cols="30" rows="10" onInput={(e) => setMailData({...mailData, message: e.target.value})}></textarea>
            </div>
            <div className="input-holder">
              <button onClick={(e) => handleFormSubmit(e)}>Submit</button>
            </div>
          </form>
        </div>
    </section>
  );
}

export default ContactForm;