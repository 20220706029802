import { useState } from 'react';
import { useParams } from 'react-router-dom';

import Footer from '../sections/Footer';
import ContactForm from '../sections/ContactForm';
import SectionTitle from '../elements/SectionTitle';
import AllWorksGallery from '../sections/AllWorksGallery';

import './WorkPage.scss';
import PhotoViewer from '../sections/PhotoViewer';

const picturesList = [
  "https://images.unsplash.com/photo-1664261840765-1d8dc5b4d7d9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
  "https://plus.unsplash.com/premium_photo-1686865496819-0607cd604fd1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2660&q=80",
  "https://images.unsplash.com/photo-1687149684845-3650ed97bc70?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80",
  "https://images.unsplash.com/photo-1687149684845-3650ed97bc70?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80",
  "https://images.unsplash.com/photo-1664261840765-1d8dc5b4d7d9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
  "https://plus.unsplash.com/premium_photo-1686865496819-0607cd604fd1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2660&q=80",
  "https://plus.unsplash.com/premium_photo-1686865496819-0607cd604fd1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2660&q=80",
  "https://images.unsplash.com/photo-1664261840765-1d8dc5b4d7d9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
  "https://images.unsplash.com/photo-1687149684845-3650ed97bc70?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80",
  "https://images.unsplash.com/photo-1664261840765-1d8dc5b4d7d9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
  "https://images.unsplash.com/photo-1664261840765-1d8dc5b4d7d9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
  "https://plus.unsplash.com/premium_photo-1686865496819-0607cd604fd1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2660&q=80",
  "https://images.unsplash.com/photo-1687149684845-3650ed97bc70?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80"
]

const WorkPage = props => {
  const [imgFocus, setImgFocus] = useState({
    visible: false,
    imgPath: ''
  });
  let { workId } = useParams()

  const showPicture = imgPath => {
    console.log(imgPath)
    setImgFocus({visible: true, imgPath: imgPath})
  }

  const hidePicture = () => {
    console.log('hide')
    setImgFocus({visible: false, imgPath: ''})
  }

  return (
    <div id="singlework" className="singlework section-page">
      <section className="singlework__hero">
        <div className="singlework__hero__details">
          <div className="singlework__hero__details--wrapper">
            <span className="singlework__hero__details--topic">—&nbsp;Couples</span>
            <h1 className="singlework__hero__details--title">Ma Boy in da field</h1>
          </div>
        </div>
        <div className="singlework__hero__picture">
          <img src="https://images.unsplash.com/photo-1664261840765-1d8dc5b4d7d9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80" alt="" />
        </div>
      </section>
      <section className="page-content">
        <div className="singlework__gallery">
            {picturesList.map((i, idx) => {
              return (<div key={idx} className="listitem">
                <img src={i} onClick={() => showPicture(i)} />
              </div>)
            })}
        </div>
      </section>
      {imgFocus.visible && <PhotoViewer hidePicture={hidePicture} img={imgFocus.imgPath} />}
      <section className='page-content'>
        <SectionTitle first="More" second="Works" />
        <AllWorksGallery />
      </section>
      <ContactForm />
      <Footer />
    </div>
  );
}

export default WorkPage;