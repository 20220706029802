import SectionTitle from '../elements/SectionTitle';

import './About.scss';

import PortraitImg from '../../assets/image/pictures/kate-contreplongee.jpg'

const About = props => {
  return (
    <section id="about" className="about-section section-page first-section">
        <div className="page-content">
          <SectionTitle first="Let me" second="Introduce" />
          <div className="about-section__wrapper">
            <div className="about-section__img">
                <img src={PortraitImg} alt="" />
              </div>
              <div className="about-section__text">
                  <h3>Right time, right place</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum pretium dui lacus, id interdum orci dignissim sed. Nulla mi dui, dapibus vel fermentum a, condimentum quis ligula. Integer vitae fringilla neque, ut pharetra lorem. Pellentesque eros purus, varius id maximus nec, maximus nec nulla. Quisque egestas quam est, nec facilisis ipsum pellentesque sed.</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum pretium dui lacus, id interdum orci dignissim sed. Nulla mi dui, dapibus vel fermentum a, condimentum quis ligula. Integer vitae fringilla neque, ut pharetra lorem. Pellentesque eros purus, varius id maximus nec, maximus nec nulla. Quisque egestas quam est, nec facilisis ipsum pellentesque sed.</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum pretium dui lacus, id interdum orci dignissim sed. Nulla mi dui, dapibus vel fermentum a, condimentum quis ligula. Integer vitae fringilla neque, ut pharetra lorem. Pellentesque eros purus, varius id maximus nec, maximus nec nulla. Quisque egestas quam est, nec facilisis ipsum pellentesque sed.</p>
              </div>
          </div>
        </div>
    </section>
  );
}

export default About;