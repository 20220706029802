import './Slide.scss';

const Slide = props => {
  return (
    <div className="image-wrapper">
      <div className="desc-box">
        <div className="desc-box__title">
          <h3>{props.headline}</h3>
        </div>
        <div className="desc-box__details">
          <span>{props.topic}</span>
          <span>{props.location}, {props.country}</span>
        </div>
      </div>
      <img src="https://images.unsplash.com/photo-1664261840765-1d8dc5b4d7d9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80" alt="" />
    </div>
  );
}

export default Slide;