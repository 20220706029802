import { useState } from 'react';

import Slide from '../elements/Slide'
import SectionTitle from '../elements/SectionTitle'

import './Works.scss';

const slideData = [
    {
      headline: 'Ma boy',
      location: 'Kyev',
      country: 'UA',
      topic: 'topic',
      src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/225363/fashion.jpg'
    },
    {
      headline: 'Ma boy',
      location: 'Kyev',
      country: 'UA',
      topic: 'topic',
      src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/225363/fashion.jpg'
    },
    {
      headline: 'Ma boy',
      location: 'Kyev',
      country: 'UA',
      topic: 'topic',
      src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/225363/fashion.jpg'
    },
    {
      headline: 'Ma boy',
      location: 'Kyev',
      country: 'UA',
      topic: 'topic',
      src: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/225363/fashion.jpg'
    }
]
  

const Works = props => {
    const [currentSlide, setCurrentSlide] = useState(1)

    const prevSlide = () => {
      console.log('prev')
      currentSlide > 1 && setCurrentSlide(currentSlide - 1)
      console.log(currentSlide)
    }

    const nextSlide = () => {
      console.log('next')
      currentSlide < slideData.length && setCurrentSlide(currentSlide + 1)
      console.log(currentSlide)
    }

    return (
        <section id="works" className="works-section section-page">
          <div className="page-content">
            <SectionTitle first="Latest" second="Works" />
            <div className="works-section__slider">
              <div className={`works-section__slider__slideshow position${currentSlide}`}>
                { slideData.map((slide, index) => {
                    return <Slide key={index} img={slide.src} headline={slide.headline} location={slide.location} country={slide.country} topic={slide.topic} index={index + 1} currentSlide={currentSlide} />
                })}
              </div>
            </div>
            <div className="arrows">
              <div className="arrow-container" onClick={() => prevSlide()}>
                <div className="arrow">
                </div>
              </div>
              <div className="arrow-container" onClick={() => nextSlide()}>
                <div className="arrow right">
                </div>
              </div>
            </div>
          </div>
        </section>
    );
}

export default Works;