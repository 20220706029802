import './Footer.scss';

const Footer = props => {
  return (
    <section id="footer" className="footer-section section-page first-section">
        <div className="page-content">
          <span className="copyright">© Kateryna Polyakova 2022</span>
          <a className="developpedby" href="http://sylvaindepardieu.fr" target="_blank">Designed and developped by Sylvain Depardieu</a>
        </div>
    </section>
  );
}

export default Footer;